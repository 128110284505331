.carousel-demo .product-item .product-item-content {
    border: 1px solid var(--surface-d);
    border-radius: 3px;
    margin: .3rem;
    text-align: center;
    padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-galleria, .p-galleria-item-nav {color: #8e8b8b8c !important}

.p-dropdown-filter{padding-right: 0px !important}

.strong{font-weight: bold;}

.alert {
    padding: 8px;
    position: relative;
    border-radius: 5px;
}

.success-alert {
    background-color: #a8f0c6;
    border-left: 5px solid #178344;
    color: #178344;
}

.danger-alert {
    background-color: #f7a7a3;
    border-left: 5px solid #8f130c;
    color: #8f130c;
}

.info-alert {
    background-color: #3f7cbb8f;
    border-left: 5px solid #4e62c4;
    color: #4e62c4;
}

.warning-alert {
    background-color: #fff39d;
    color: #936619;
}

.simple-alert {
    background-color: #ebebeb;
    border-left: 5px solid #6c6c6c;
    color: #6c6c6c;
}

.imgtxtc {
    display: inline-flex !important;
    vertical-align: middle !important;
}
ul.p-inputtext.p-chips-multiple-container{
    width: 100% !important
}

div.p-chips>ul.p-inputtext, #codigo-drop.p-dropdown.p-focus{
    border: 0px !important;
    box-shadow: 0 0 0 0.2rem #fff !important;
}

.p-chips{box-shadow: 0 0 0 0.2rem #fff !important;}

.white-text{color: #fff !important}

.btn {
    border-radius: 12px;
    background-color: #1C1C1C !important;
}

input:not([type]):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
    border-bottom: 1px solid #2F56A3;
    box-shadow: 0 1px 0 0 #2F56A3;
}

.dropdown-content li>a,
.dropdown-content li>span,
.input-field .prefix.active,
input:not([type]):focus:not([readonly])+label,
input[type=date]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=number]:not(.browser-default):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label,
input[type=search]:not(.browser-default):focus:not([readonly])+label,
input[type=tel]:not(.browser-default):focus:not([readonly])+label,
input[type=text]:not(.browser-default):focus:not([readonly])+label,
input[type=time]:not(.browser-default):focus:not([readonly])+label,
input[type=url]:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label {
    color: #2F56A3;
}

a{
    color: #2F56A3 !important
}